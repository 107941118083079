import '../css/app.scss';

import 'cookieconsent';
import './bootstrap.min';
import './jquery.flexslider-min';
import './flex-slider';
import hljs from 'highlight.js';
import mapboxgl from 'mapbox-gl';

$(document).ready(function() {
    'use strict';

    $("#back-top").hide();

    $(function () {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                $('#back-top').fadeIn();
            } else {
                $('#back-top').fadeOut();
            }
        });

        // scroll body to 0px on click
        $('#back-top a').click(function () {
            $('body,html').animate({
                scrollTop: 0
            }, 600);
            return false;
        });
    });

    hljs.initHighlightingOnLoad();

    initMap();
});

if ( $('#nav').length ) {
    initAffixCheck();
};

/* --------------------------------------------
  FIXED HEADER ON - OFF
--------------------------------------------- */
function initAffixCheck(){
    'use strict';
    var navAffix = $('#nav');

    //FIXED HEADER ON
    navAffix.affix({
        offset: { top: 1, }
    });

    if(($(window).width() < 1025) && (!$('#nav').hasClass('affix-on-mobile')) ) {
        //FIXED HEADER OFF
        $(window).off('.affix');
        navAffix.removeData('bs.affix').removeClass('affix affix-top affix-bottom');
    };

    if ($('#nav').hasClass('affix-on-mobile')) {
        $(".nav.navbar-nav").css("max-height", $(window).height() - $(".logo-row").height() - 20 + "px");
    }
};

/* ---------------------------------------------
 MAP
 --------------------------------------------- */
function initMap() {
    (function($) {

        $('.google-map').each(function () {
            
            if ($(this).length) {
                var divId = $(this).attr('id'),
                    lat = $(this).data('latitude'),
                    long = $(this).data('longitude'),
                    zoom = $(this).data('zoom');

                mapboxgl.accessToken = 'pk.eyJ1IjoidmFiYWR1cyIsImEiOiJjanNpcTlhOGQwamxxM3luNTAwa2FqMjJ3In0.7pFNoHHh2sLvstTFzRO43g';
                var map = new mapboxgl.Map({
                    container: divId, // container id
                    style: 'mapbox://styles/mapbox/light-v10', //hosted style id
                    center: [lat, long], // starting position
                    zoom: zoom // starting zoom
                });
                var marker = new mapboxgl.Marker({
                    color: '#000'
                })
                    .setLngLat([lat, long])
                    .addTo(map);
            }
        });

    })(jQuery);
}